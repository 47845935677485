<template>
  <v-content class="page-content">
    <v-row class="pb-16 page-main-header">
      <v-col cols="12">
        <v-row>
          <v-col cols="10" offset="1" class="text-center">
            <span class="text-center page-main-title text-h6"
              >SISTEMA DE INFORMACIÓN DE GOBIERNO PROPIO</span
            >
            <br />
            <span class="pt-1 text-center page-title text-h4"
              >COMPONENTES PLANES DE VIDA</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-n15">
      <v-col cols="10" offset="1">
        <v-row>
          <v-col
            v-if="$canArray(permissionsComponent.CENSUS)"
            class="pl-5 pr-5 mb-8 d-flex justify-center"
            cols="4"
          >
            <v-card
              elevation="15"
              class="text-center rounded-lg"
              max-width="371"
              color="#EFEFEF"
            >
              <v-img height="80" src="../assets/background/img-login-web.png">
              </v-img>
              <v-img
                src="../assets/images/ico-censo.png"
                class="mx-auto mt-n16 background-card-img"
              ></v-img>
              <h5 class="mt-2 mb-0">COMPONENTE</h5>
              <h3 class="mt-0">CENSO INDÍGENA</h3>
              <v-btn
                @click="setAppComponent('CENSUS')"
                rounded
                color="error mb-n5"
              >
                ENTRAR
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </v-card>
          </v-col>
          <v-col
            v-if="$canArray(permissionsComponent.DDHH)"
            class="pl-5 pr-5 mb-8 d-flex justify-center"
            cols="4"
          >
            <v-card
              elevation="15"
              class="text-center rounded-lg"
              max-width="371"
              color="#EFEFEF"
            >
              <v-img height="80" src="../assets/background/img-login-web.png">
              </v-img>
              <v-img
                src="../assets/images/ico-ddhh.png"
                class="mx-auto mt-n16 background-card-img"
              ></v-img>
              <h5 class="mt-2 mb-0">COMPONENTE</h5>
              <h3 class="mt-0">OBSERVATORIO DV DD.HH.</h3>
              <v-btn
                @click="setAppComponent('DDHH')"
                rounded
                color="error mb-n5"
              >
                ENTRAR
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </v-card>
          </v-col>
          <v-col
            v-if="$canArray(permissionsComponent.DDHH_WOMEN)"
            class="pl-5 pr-5 mb-8 d-flex justify-center"
            cols="4"
          >
            <v-card
              elevation="15"
              class="text-center rounded-lg"
              max-width="371"
              color="#EFEFEF"
            >
              <v-img height="80" src="../assets/background/img-login-web.png">
              </v-img>
              <v-img
                src="../assets/images/ico-mujeres.png"
                class="mx-auto mt-n16 background-card-img"
              ></v-img>
              <h5 class="mt-2 mb-0">COMPONENTE</h5>
              <h3 class="mt-0">OBSERVATORIO VBG MUJERES</h3>
              <v-btn
                @click="setAppComponent('DDHH_WOMEN')"
                rounded
                color="error mb-n5"
              >
                ENTRAR
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </v-card>
          </v-col>
          <v-col
            v-if="$canArray(permissionsComponent.SELF_JUSTICE)"
            class="pl-5 pr-5 mb-8 d-flex justify-center"
            cols="4"
          >
            <v-card
              elevation="15"
              class="text-center rounded-lg"
              max-width="371"
              color="#EFEFEF"
            >
              <v-img height="80" src="../assets/background/img-login-web.png">
              </v-img>
              <v-img
                src="../assets/images/ico-justicia-propia.png"
                class="mx-auto mt-n16 background-card-img"
              ></v-img>
              <h5 class="mt-2 mb-0">COMPONENTE</h5>
              <h3 class="mt-0">JUSTICIA PROPIA</h3>
              <v-btn
                @click="setAppComponent('SELF_JUSTICE')"
                rounded
                color="error mb-n5"
              >
                ENTRAR
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </v-card>
          </v-col>
          <v-col v-if="$canArray(permissionsComponent.PLANNING)"
           class="pl-5 pr-5 mb-8 d-flex justify-center" cols="4">
            <v-card
              elevation="15"
              class="text-center rounded-lg"
              max-width="371"
              color="#EFEFEF"
            >
              <v-img height="80" src="../assets/background/img-login-web.png">
              </v-img>
              <v-img
                src="../assets/images/ico-planeacion.png"
                class="mx-auto mt-n16 background-card-img"
              ></v-img>
              <h5 class="mt-2 mb-0">COMPONENTE</h5>
              <h3 class="mt-0">PLANEACIÓN</h3>
              <v-btn
                @click="setAppComponent('PLANNING')"
                rounded
                color="error mb-n5"
              >
                ENTRAR
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </v-card>
          </v-col>
          <v-col v-if="$canArray(permissionsComponent.ORDINARY_JUSTICE)" class="pl-5 pr-5 mb-8 d-flex justify-center" cols="4">
            <v-card
              elevation="15"
              class="text-center rounded-lg"
              max-width="371"
              color="#EFEFEF"
            >
              <v-img height="80" src="../assets/background/img-login-web.png">
              </v-img>
              <v-img
                src="../assets/images/ico-justicia-ordinaria.png"
                class="mx-auto mt-n16 background-card-img"
              ></v-img>
              <h5 class="mt-2 mb-0">COMPONENTE</h5>
              <h3 class="mt-0">JUSTICIA ORDINARIA</h3>
              <v-btn
                @click="setAppComponent('ORDINARY_JUSTICE')"
                rounded
                color="error mb-n5"
              >
                ENTRAR
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-content>
</template>

<script>
import { mapActions } from "vuex";
import PlanPermissionsEnum from "@/enums/planPermissionsEnum";
export default {
  name: "components",
  data() {
    return {
      permissionsComponent: {
        DDHH: ["view_ddhh"],
        DDHH_WOMEN: ["view_ddhh_women"],
        CENSUS: ["view_census"],
        SELF_JUSTICE: ["view_self_justice"],
        PLANNING: [PlanPermissionsEnum.VIEW_PLANNING],
        ORDINARY_JUSTICE: ["view_ordinary_justice"],
      },
    };
  },
  methods: {
    ...mapActions([
      "setComponent",
      "getSelfJusticeReferenceValue",
      "getPlanningReferenceValue",
      "getOrdinaryJusticeReferenceValue",
    ]),
    async setAppComponent(component) {

      this.setComponent(component);

      const to = {
        DDHH: "humansRights.dashboard",
        DDHH_WOMEN: "womenRights.dashboard",
        CENSUS: "census.dashboard",
        SELF_JUSTICE: "selfjustice.dashboard",
        PLANNING: "planning.dashboard",
        ORDINARY_JUSTICE: "ordinary-justice.dashboard",
      };

      const callbacks = {
        SELF_JUSTICE: "getSelfJusticeReferenceValue",
        PLANNING: "getPlanningReferenceValue",
        ORDINARY_JUSTICE: "getOrdinaryJusticeReferenceValue",
      };

      if (callbacks[component]) await this[callbacks[component]]();

      await this.$router.push({
        name: to[component],
      });

    },
  },
};
</script>

<style scoped>
.background-card-img {
  border-radius: 50% 50% !important;
  border: white 5px solid;
  height: 100px;
  width: 100px;
}

.page-title {
  color: #43794e;
  border-bottom: 2px solid #dd3333;
  font-size: 38px;
}

.page-main-title {
  color: #43794e;
  font-size: 18px;
  font-weight: bold;
}

.page-main-header {
  background-color: #efefef;
  padding-top: 24px;
}

.page-content {
  padding-top: 0 !important;
}

.rounded-lg {
  border-radius: 18px !important;
}

/* CSS DASHBOARDS MODULOS */

@media only screen and (max-width: 1366px)
{
  .v-application .pl-5 .v-card.v-sheet {
    max-width: 100%!important;
  }
}

@media only screen and (max-width: 959px)
{
  .v-application .pl-5 .v-card.v-sheet {
    max-width: 100%!important;
  }

  .v-application .pl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

}

@media only screen and (max-width: 768px)
{

  .page-main-header.pb-16{
    padding-top: 60px;
    padding-bottom:100px!important;
  }

  .v-application .pl-5 .v-card.v-sheet {
    max-width: 100%!important;
  }

  .v-application .pl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding:10px 0px!important;
  }

  .v-toolbar__content .v-btn.red span.v-btn__content {
    text-indent: -9999px;
  }
}
</style>
