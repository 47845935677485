const PlanPermissionsEnum = {
  VIEW_PLANNING: "view_planning",
  VIEW_REPORTS_PLANNING: "view_reports_planning",
  VIEW_LIST_PLANNING: "list_cases_planning",
  
};

Object.freeze(PlanPermissionsEnum);

export default PlanPermissionsEnum;
